import { KeyboardArrowDown } from '@mui/icons-material'
import { InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import classNames from 'classnames'
import { exposedLocales } from 'i18n/exposedLocales'
import { useLocalizedUrlMap } from 'i18n/useLocalizedUrlMap'
import { useRouter } from 'next/router'
import { forwardRef, memo, ReactNode, useCallback, useMemo } from 'react'
import { CountryFlag } from './CountryFlag'
import styles from './locales.module.scss'

type Props = {
  locale: TLocale
  className?: string
}

export type SelectOption = {
  value: string
  key: string
  iconComponent?: ReactNode
}

const ArrowDownIconColorInherit = forwardRef<SVGSVGElement>((props, ref) => (
  <div className={styles.arrowDown}>
    <KeyboardArrowDown ref={ref} fontSize='inherit' />
  </div>
))
ArrowDownIconColorInherit.displayName = 'WhiteArrowDownIcon'

export const Locales: React.FC<Props> = memo(({ locale, className }) => {
  const localizations = useLocalizedUrlMap()
  const router = useRouter()
  const localeOptions = useMemo(() => {
    return exposedLocales.map((locale) => {
      return {
        value: locale,
        key: locale,
        iconComponent: <CountryFlag locale={locale} localeName={locale} />,
      }
    })
  }, [])

  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      const locale = event.target.value as TLocale
      router.push(localizations[locale], undefined, { locale: false })
    },
    [localizations, router]
  )

  return (
    <div className={styles.localesContainer}>
      <Select
        input={<InputBase className={styles.input} />}
        className={classNames(styles.select, className)}
        value={locale}
        onChange={handleOnChange}
        IconComponent={ArrowDownIconColorInherit}
        variant='standard'
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {localeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <div className={styles.menuItem}>
              {option.iconComponent && (
                <div className={styles.menuItemIcon}>
                  {option.iconComponent}
                </div>
              )}
              {option.key}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
})

Locales.displayName = 'Locales'
