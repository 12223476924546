import { useEnterPressHandler } from '@internal/utils/keyboardEvents/useEnterPressHandler'
import { Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import React, { useCallback, useMemo } from 'react'
import styles from './searchInput.module.scss'
import { useTypewriterEffect } from './useTypewriterEffect'

interface SearchInputProps {
  locale: TLocale
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { locale } = props

  const handleSearch = useCallback(() => {
    console.log('search')
  }, [])

  const { handleEnterPress } = useEnterPressHandler(handleSearch, [
    handleSearch,
  ])

  // TODO - use real search terms and translations
  const placeHolderPrefix = 'Search for'
  const words = useMemo(() => ['tractors', 'combines', 'balers', 'brands'], [])
  const placeholderText = useTypewriterEffect(placeHolderPrefix, words)

  return (
    <div className={styles.searchInputWrapper}>
      <input
        className={styles.searchInput}
        placeholder={placeholderText}
        onKeyDown={handleEnterPress}
      />
      <IconButton className={styles.iconButton} onClick={handleSearch}>
        <Search fontSize='small' />
      </IconButton>
    </div>
  )
}

SearchInput.displayName = 'SearchInput'
