import { muiClassFixPositionOnModalOpen } from '@internal/utils/constants'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { TNavigation } from 'utils/navigation/TNavigation'
import { AuctionBanner } from './AuctionBanner/AuctionBanner'
import styles from './header.module.scss'
import { HolidayBanner } from './HolidayBanner/HolidayBanner'
import { MobileMenu } from './MobileMenu/MobileMenu'
import { NavigationBar } from './NavigationBar/NavigationBar'
import { SalesAgentAwayBanner } from './SalesAgentAwayBanner/SalesAgentAwayBanner'
import { DesktopSearchBar } from './SearchBar/DesktopSearchBar'
import { MobileSearchBar } from './SearchBar/MobileSearchBar'
import { TSearchParams } from './SearchOverlay/SearchOverlay'
export interface Props {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  footerNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  searchParams: TSearchParams
}

export const Header: React.FC<Props> = React.memo((props) => {
  const { isTablet, isMobile } = useMediaQuery()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const showMobileMenu = useCallback(() => setMobileMenuOpen(true), [])
  const hideMobileMenu = useCallback(() => setMobileMenuOpen(false), [])

  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    footerNavigation,
    brandsNavigation,
    searchParams,
  } = props

  const { getMachineTypeName } = useMachineTypeName()
  const allOffersUrl = useMemo(
    () =>
      urlFor(locale, {
        key: PageTypeKey.MachineCategoryPage,
        machineTypeName: getMachineTypeName(MachineType.TRACTOR, 'plural'),
      }),
    [getMachineTypeName, locale]
  )

  return (
    <>
      <div className={styles.headerSpacer} />
      <header
        className={classNames(
          styles.headerContainer,
          muiClassFixPositionOnModalOpen
        )}
      >
        {isMobile && (
          <MobileSearchBar
            locale={locale}
            searchParams={searchParams}
            onClick={showMobileMenu}
          />
        )}
        {!isMobile && (
          <>
            <DesktopSearchBar locale={locale} searchParams={searchParams} />
            <NavigationBar
              locale={locale}
              primaryNavigation={primaryNavigation}
              secondaryNavigation={secondaryNavigation}
              allOffersUrl={allOffersUrl}
              brandsNavigation={brandsNavigation}
            />
          </>
        )}
      </header>
      {<AuctionBanner locale={props.locale} />}
      {false && <HolidayBanner />}
      {false && (
        <SalesAgentAwayBanner
          name='Istvan'
          from={new Date('2024, 1, 31')}
          to={new Date('2024, 2, 19')}
        />
      )}
      {isTablet &&
        createPortal(
          <MobileMenu
            open={mobileMenuOpen}
            onCloseClick={hideMobileMenu}
            primaryNavigation={primaryNavigation}
            secondaryNavigation={secondaryNavigation}
            brandsNavigation={brandsNavigation}
            footerNavigation={footerNavigation}
            locale={props.locale}
            allOffersUrl={allOffersUrl}
          />,
          document.body
        )}
    </>
  )
})

Header.displayName = 'Header'
