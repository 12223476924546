import { SettingsContext } from '@internal/page/SettingsContext'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { Typography } from '@renderer-ui-library/mui'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useContext, useMemo } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import styles from './auctionBanner.module.scss'

type Props = {
  locale: TLocale
}

const timerCountdown = (date: Dayjs) => {
  const now = dayjs()
  const minutesDiffToDate = date.diff(now, 'minutes')
  const days = Math.floor(minutesDiffToDate / 60 / 24)
  const daysInMinutes = days * 60 * 24
  const hours = Math.floor((minutesDiffToDate - daysInMinutes) / 60)
  const hoursInMinutes = hours * 60
  const minutes = Math.floor(minutesDiffToDate - daysInMinutes - hoursInMinutes)

  return {
    days,
    hours,
    minutes,
  }
}

export const AuctionBanner: React.FC<Props> = React.memo(({ locale }) => {
  const { t } = useTranslation(localeNamespace.common)
  const { auctionStartAt, auctionEndAt } = useContext(SettingsContext)
  const { startsAt, endsAt, startCountdown, endCountdown } = useMemo(() => {
    const startsAt = auctionStartAt ? dayjs(auctionStartAt) : null
    const endsAt = auctionEndAt ? dayjs(auctionEndAt) : null
    return {
      startsAt,
      endsAt,
      startCountdown: startsAt ? timerCountdown(startsAt) : null,
      endCountdown: endsAt ? timerCountdown(endsAt) : null,
    }
  }, [auctionEndAt, auctionStartAt])

  const handleClick = useCallback(() => {
    tracker.trackEvent({
      name: 'click',
      target_name: 'top_banner.auction',
      is_interaction: true,
    })
  }, [])

  const countdownText = useMemo(() => {
    if (!startCountdown || !endCountdown) {
      return ''
    }
    // auction will start in more than 1 day
    if (startCountdown.days > 0) {
      return t(translations.auctionBannerStartCountdownDaysText, {
        days: startCountdown.days,
      })
      // auction will start in less than 1 day
    } else if (startCountdown.days === 0) {
      return t(translations.auctionBannerStartCountdownHoursText, {
        hours: startCountdown.hours,
        minutes: startCountdown.minutes,
      })
    }

    if (endCountdown.days === 0) {
      return t(translations.auctionBannerEndCountdownHoursText, {
        hours: endCountdown.hours,
        minutes: endCountdown.minutes,
      })
      // auction ends in more than 1 day
    } else if (endCountdown.days > 0) {
      return t(translations.auctionBannerEndCountdownDaysText, {
        days: endCountdown.days,
      })
    }

    return ''
  }, [endCountdown, startCountdown, t])

  if (
    !startCountdown ||
    !endCountdown ||
    !startsAt ||
    !endsAt ||
    startCountdown.days > 7
  ) {
    return null
  }

  return (
    <a
      className={classNames(styles.outerWrapper, 'auction-banner')}
      onClick={handleClick}
      href={urlFor(locale, 'auctions')}
    >
      <HeaderContentWrapper className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <Typography variant='h6' className={styles.countdownText}>
            {countdownText}
          </Typography>
          <Typography
            variant='body1'
            textTransform='uppercase'
            fontWeight={700}
          >
            {t(translations.auctionBannerCTA)}
          </Typography>
        </div>
      </HeaderContentWrapper>
    </a>
  )
})

AuctionBanner.displayName = 'AuctionBanner'
