import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import { Typography } from '@mui/material'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import Link from 'next/link'
import React from 'react'
import { getNavigationItemLabel } from '../../../getNavigationItemLabel'
import { Subnavigation } from './Subnavigation/Subnavigation'

interface NavigationItemProps {
  locale: TLocale
  entry: TNavigationEntry
}

export const NavigationItem: React.FC<NavigationItemProps> = (props) => {
  const { locale, entry } = props

  return (
    <>
      {entry.children.length === 0 && (
        <Link
          href={entry.route}
          passHref
          prefetch={false}
          locale={locale}
          target={entry.external ? '_blank' : undefined}
          tabIndex={0}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'body1'}>
              {getNavigationItemLabel(entry)}
            </Typography>
          </div>
        </Link>
      )}
      {entry.children.length > 0 && (
        <Subnavigation entry={entry} locale={locale} />
      )}
    </>
  )
}

NavigationItem.displayName = 'NavigationItem'
