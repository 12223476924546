import { ImageLoader } from '@internal/utils/media/ImageLoader'

import { SystemPageKey } from '@internal/page/SystemPageKey'
import { useFeatureFlag } from '@internal/utils/featureFlags/useFeatureFlag'
import { urlForSystemPageWithoutParams } from '@internal/utils/routing/urls/urlForSystemPageWithoutParams'
import { Menu } from '@mui/icons-material'
import { Grid } from '@mui/material'
import Logo from '@renderer-ui-library/assets/logo.svg'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import Image from 'next/image'
import React, { useMemo } from 'react'
import { Locales } from '../Locales/Locales'
import { SearchOverlay, TSearchParams } from '../SearchOverlay/SearchOverlay'
import styles from './searchBar.module.scss'
import { SearchInput } from './SearchInput/SearchInput'

export const portalSlotMobileId = 'navigation-portal-slot-mobile'
interface SearchBarProps {
  locale: TLocale
  searchParams: TSearchParams
  onClick: () => void
}

export const MobileSearchBar: React.FC<SearchBarProps> = (props) => {
  const { locale, searchParams } = props
  const { hasFeature } = useFeatureFlag()
  const isHeaderSearchInputEnabled = hasFeature('headerSearchInput')
  const startPageUrl = useMemo(
    () => urlForSystemPageWithoutParams(locale, SystemPageKey.StartPage),
    [locale]
  )

  return (
    <div className={styles.searchBar}>
      <Grid
        container
        rowGap={3}
        alignItems='center'
        className={styles.container}
      >
        <Grid item xs={6}>
          <a href={startPageUrl} className={styles.logo}>
            <Image
              loading='lazy'
              src={Logo.src}
              alt='E-FARM GmbH'
              loader={ImageLoader}
              fill
            />
          </a>
        </Grid>
        <Grid item xs={6} className={styles.localesWrapper}>
          <div id={portalSlotMobileId}></div>
          <Locales locale={locale} className={styles.locales} />
        </Grid>
        <Grid item xs={12} className={styles.mobileMenuWrapper}>
          <div
            className={styles.mobileMenuButton}
            tabIndex={1}
            onClick={props.onClick}
          >
            <Menu color='inherit' fontSize='inherit' />
          </div>
          {isHeaderSearchInputEnabled && <SearchInput locale={locale} />}
          {!isHeaderSearchInputEnabled && (
            <SearchOverlay searchParams={searchParams} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

MobileSearchBar.displayName = 'MobileSearchBar'
