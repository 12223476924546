import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  ClickAwayListener,
  Paper,
  Popover,
  Typography,
} from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import classNames from 'classnames'
import Link from 'next/link'
import { useCallback, useState } from 'react'
import { getNavigationItemLabel } from '../../../../getNavigationItemLabel'
import styles from './subnavigation.module.scss'

export type Props = {
  locale: TLocale
  entry: TNavigationEntry
}

export const Subnavigation: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { locale, entry } = props

  const toggleOpen = useCallback(
    (ev: React.MouseEvent<HTMLElement>) =>
      setAnchorEl((anchorEl) => {
        if (anchorEl) {
          return null
        }
        return ev.currentTarget as HTMLElement
      }),
    []
  )
  const close = useCallback(() => setAnchorEl(null), [])

  const handleLinkClick = useCallback((event: React.MouseEvent) => {
    const openLink = event.ctrlKey || event.metaKey
    if (openLink) {
      return
    }
    event.preventDefault()
  }, [])

  const isOpen = !!anchorEl

  return (
    <div className={styles.wrapper}>
      {/* We're wrapping this in a Link for SEO */}
      <Link
        href={entry.route}
        prefetch={false}
        locale={locale}
        onClick={handleLinkClick}
      >
        <Typography
          className={styles.button}
          onClick={toggleOpen}
          color={Colors.textPrimary}
          variant={'body1'}
          component='div'
        >
          {getNavigationItemLabel(entry)}
          <div className={classNames(styles.icon, { [styles.open]: isOpen })}>
            <KeyboardArrowDown fontSize='inherit' />
          </div>
        </Typography>
      </Link>
      <Popover
        disableScrollLock
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{
          vertical: 36,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={styles.popover}
      >
        <ClickAwayListener onClickAway={close}>
          <Paper elevation={4}>
            <div className={styles.content}>
              {entry.children.map((entry) => (
                <div className={styles.navigationItem} key={entry.id}>
                  <Link
                    href={entry.route}
                    prefetch={false}
                    locale={locale}
                    onClick={close}
                    target={entry.external ? '_blank' : undefined}
                  >
                    {getNavigationItemLabel(entry)}
                  </Link>
                </div>
              ))}
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div>
  )
}

Subnavigation.displayName = 'Subnavigation'
