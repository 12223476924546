import { TNavigation } from '@internal/utils/navigation/TNavigation'
import { Container, Divider } from '@mui/material'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import React from 'react'
import { HeaderPhoneNumber } from './HeaderPhoneNumber/HeaderPhoneNumber'
import { Navigation } from './Navigation/Navigation'
import styles from './navigationBar.module.scss'
interface NavigationBarProps {
  locale: TLocale
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  brandsNavigation: TNavigation
  allOffersUrl: string
}

export const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    brandsNavigation,
    allOffersUrl,
  } = props

  return (
    <div className={styles.navigationBar}>
      <Container maxWidth='lg' disableGutters className={styles.container}>
        <HeaderPhoneNumber locale={locale} />
        <div className={styles.navigations}>
          <Navigation
            items={primaryNavigation}
            locale={locale}
            brandsNavigation={brandsNavigation}
            allOffersUrl={allOffersUrl}
          />
          <Divider
            className={styles.divider}
            color={Colors.textPrimary}
            orientation='vertical'
          />
          <Navigation
            items={secondaryNavigation}
            locale={locale}
            brandsNavigation={brandsNavigation}
            allOffersUrl={allOffersUrl}
          />
        </div>
      </Container>
    </div>
  )
}

NavigationBar.displayName = 'NavigationBar'
