export enum NavigationKey {
  Header = 'header',
  HeaderSecondary = 'headerSecondary',
  Services = 'services',
  Footer0 = 'footer0',
  Footer1 = 'footer1',
  Footer2 = 'footer2',
  HeaderBrands = 'headerBrands',
  MainNavigationPrimary = 'mainNavigationPrimary',
  MainNavigationSecondary = 'mainNavigationSecondary',
}
