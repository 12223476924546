import { DynamicAuctionBlock } from './AuctionBlock/dynamicAuctionBlock'
import { DynamicBenefitsBlock } from './BenefitsBlock/dynamicBenefitsBlock'
import { BlockProps } from './BlockProps'
import { BlockType } from './BlockType'
import { DynamicCardBannerBlock } from './CardBannerBlock/dynamicCardBannerBlock'
import { DynamicCompareMachinesBlock } from './CompareMachinesBlock/dynamicCompareMachinesBlock'
import { DynamicContactBlock } from './ContactBlock/dynamicContactBlock'
import { DynamicCookieListBlock } from './CookieListBlock/dynamicCookiesListBlock'
import { DynamicCoverBlock } from './CoverBlock/dynamicCoverBlock'
import { DynamicFaqBlock } from './FrquentlyAskedQuestionsBlock/dynamicFaqBlock'
import { DynamicHappyCustomersMapBlock } from './HappyCustomersMapBlock/dynamicHappyCutomerMapBlock'
import { IconColumnsBlock } from './IconColumnsBlock/IconColumnsBlock'
import { MachineCategoryPagesListingBlock } from './MachineCategoryPagesListingBlock/MachineCategoryPagesListingBlock'
import { DynamicMachineDetailsBlock } from './MachineDetailsBlock/dynamicMachineDetailsBlock'
import { DynamicMachineOverviewBlock } from './MachineOverviewBlock/dynamicMachineOverviewBlock'
import { DynamicMachineryFeedSliderBlock } from './MachineryFeedSlider/dynamicMachineryFeedSlider'
import { MediaColumnsBlock } from './MediaColumnsBlock/MediaColumnsBlock'
import { MediaGridBlock } from './MediaGridBlock/MediaGridBlock'
import { MediaSplitColumnsBlock } from './MediaSplitColumnsBlock/MediaSplitColumnsBlock'
import { DynamicNotFoundBlock } from './NotFoundBlock/dynamicNotFoundBlock'
import { NumberedFeaturesColumnsBlock } from './NumberedFeaturesColumnsBlock/NumberedFeaturesColumnsBlock'
import { DynamicPopularMachineTypeBlock } from './PopularMachineTypeBlock/dynamicPopularMachineTypeBlock'
import { DynamicPromoteServiceBlock } from './PromoteServiceBlock/dynamicPromoteServiceBlock'
import { DynamicSearchCoverBlock } from './SearchCoverBlock/dynamicSearchCoverBlock'
import { DynamicSearchResultsBlock } from './SearchResultsBlock/dynamicSearchResultsBlock'
import { DynamicServiceBlock } from './ServiceBlock/dynamicServiceBlock'
import { DynamicSimilarMachinesBlock } from './SimilarMachinesBlock/dynamicSimilarMachinesBlock'
import { SimpleTextBlock } from './SimpleTextBlock/SimpleTextBlock'
import { DynamicSpecialistColumnsBlock } from './SpecialistColumnsBlock/dynamicSpecialistColumnsBlock'
import { DynamicTestimonialsBlock } from './TestimonialsBlock/dynamicTestimonialsBlock'
import { DynamicTextImageSplitBlock } from './TextImageSplitBlock/dynamicTextImageSplitBlock'

const blocksRegistry: Record<BlockType, React.ComponentType<any>> = {
  [BlockType.SimpleTextBlock]: SimpleTextBlock,
  [BlockType.MachineDetails]: DynamicMachineDetailsBlock,
  [BlockType.MachineOverview]: DynamicMachineOverviewBlock,
  [BlockType.SearchResults]: DynamicSearchResultsBlock,
  [BlockType.CoverBlock]: DynamicCoverBlock,
  [BlockType.SearchCoverBlock]: DynamicSearchCoverBlock,
  [BlockType.TextImageSplitBlock]: DynamicTextImageSplitBlock,
  [BlockType.IconColumnsBlock]: IconColumnsBlock,
  [BlockType.MediaColumnsBlock]: MediaColumnsBlock,
  [BlockType.MediaGridBlock]: MediaGridBlock,
  [BlockType.MediaSplitColumnsBlock]: MediaSplitColumnsBlock,
  [BlockType.SpecialistColumnsBlock]: DynamicSpecialistColumnsBlock,
  [BlockType.NumberedFeaturesColumnsBlock]: NumberedFeaturesColumnsBlock,
  [BlockType.DynamicCookieListBlock]: DynamicCookieListBlock,
  [BlockType.NotFoundBlock]: DynamicNotFoundBlock,
  [BlockType.SimilarMachinesBlock]: DynamicSimilarMachinesBlock,
  [BlockType.PromoteServiceBlock]: DynamicPromoteServiceBlock,
  [BlockType.CompareMachinesBlock]: DynamicCompareMachinesBlock,
  [BlockType.PopularMachineBlock]: DynamicPopularMachineTypeBlock,
  [BlockType.ServiceBlock]: DynamicServiceBlock,
  [BlockType.TestimonialsBlock]: DynamicTestimonialsBlock,
  [BlockType.ContactBlock]: DynamicContactBlock,
  [BlockType.AuctionBlock]: DynamicAuctionBlock,
  [BlockType.FaqBlock]: DynamicFaqBlock,
  [BlockType.MachineryFeedSliderBlock]: DynamicMachineryFeedSliderBlock,
  [BlockType.CardBannerBlock]: DynamicCardBannerBlock,
  [BlockType.HappyCustomerMapBlock]: DynamicHappyCustomersMapBlock,
  [BlockType.BenefitsBlock]: DynamicBenefitsBlock,
  [BlockType.MachineCategoryPagesListingBlock]:
    MachineCategoryPagesListingBlock,
}

export const getBlockComponent = (
  type: BlockType
): React.ComponentType<BlockProps> | undefined => blocksRegistry[type]
