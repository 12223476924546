import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import { createContext, FC, memo, PropsWithChildren } from 'react'

export type CustomerServiceNumbers = Partial<
  Record<
    TLocale,
    {
      displayNumber: string
      linkNumber: string
    }
  >
>

export type Settings = {
  isAuctionLive: boolean
  rollout1: number
  rollout2: number
  auctionId: string
  auctionStartAt: string | null
  auctionEndAt: string | null
  customerServiceNumbers: CustomerServiceNumbers
}

export const defaultSettings: Settings = {
  isAuctionLive: false,
  rollout1: 0,
  rollout2: 0,
  auctionId: '',
  auctionStartAt: null,
  auctionEndAt: null,
  customerServiceNumbers: {},
}

export const SettingsContext = createContext<Settings>(defaultSettings)

export const SettingsProvider: FC<PropsWithChildren<{ settings: Settings }>> =
  memo(({ children, settings }) => (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  ))

SettingsProvider.displayName = 'SettingsProvider'
