import { useEffect, useRef, useState } from 'react'

const TYPE_SPEED = 100
const PAUSE_TIME = 2000

export const useTypewriterEffect = (prefix: string, words: string[]) => {
  const [placeholderText, setPlaceholderText] = useState(prefix)
  const wordIndexRef = useRef(0)
  const charIndexRef = useRef(0)
  const isDeletingRef = useRef(false)
  const isPausingRef = useRef(false)

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[wordIndexRef.current]
      const currentText = currentWord.slice(0, charIndexRef.current)

      setPlaceholderText(`${prefix} ${currentText}`)

      if (!isDeletingRef.current) {
        if (charIndexRef.current < currentWord.length) {
          charIndexRef.current += 1
        } else if (!isPausingRef.current) {
          isPausingRef.current = true
          setTimeout(() => {
            isDeletingRef.current = true
            isPausingRef.current = false
          }, PAUSE_TIME)
        }
      } else {
        if (charIndexRef.current > 0) {
          charIndexRef.current -= 2
        } else {
          isDeletingRef.current = false
          wordIndexRef.current = (wordIndexRef.current + 1) % words.length
        }
      }
    }

    const interval = setInterval(handleTyping, TYPE_SPEED)
    return () => clearInterval(interval)
  }, [prefix, words])

  return placeholderText
}
