import {
  Machine,
  OldMachineDTO,
  oldMachineDTOtoMachine,
} from '@internal/utils/machine/Machine'
import {
  ICallbackRequestForm,
  IContactForm,
  IRequestLeasingForm,
} from '@website-shared-library/forms'
import { KnownMachineAttribute } from '@website-shared-library/machine/KnownMachineAttribute'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { SortOrder } from '@website-shared-library/search/SortOrder'
import { TSearchFilter } from '@website-shared-library/search/TSearchFilter'
import { TSearchFilterBoolean } from '@website-shared-library/search/TSearchFilterBoolean'
import { TSearchPayload } from '@website-shared-library/search/TSearchPayload'
import { TSearchSortOption } from '@website-shared-library/search/TSearchSortOption'
import axios from 'axios'
import { environment } from 'environment/environment'
import { getAxiosData } from 'utils/api/getAxiosData'
import { TSearchResult } from './TSearchResult'

const { BACKEND_URL } = environment

const unauthenticatedBackendClient = axios.create({
  baseURL: BACKEND_URL,
  timeout: 30000,
})

export const searchMachine = async (
  params: TSearchPayload,
  controller?: AbortController
): Promise<TSearchResult> => {
  const excludeMachinesWithoutMedia: TSearchFilterBoolean = {
    type: 'boolean',
    attribute: 'excludeMachinesWithoutMedia',
  }
  return unauthenticatedBackendClient
    .post<{ results: OldMachineDTO[]; count: number }>(
      `/machines/search`,
      {
        ...params,
        filters: [...params.filters, excludeMachinesWithoutMedia],
      },
      {
        signal: controller?.signal,
      }
    )
    .then(getAxiosData)
    .then((res) => ({
      results: res.results.map(oldMachineDTOtoMachine),
      count: res.count,
    }))
}

export const getSimilarMachines = async ({
  machine,
  includeBrand,
  ip,
  amount,
}: {
  machine: Machine
  includeBrand: boolean
  ip: string | null
  amount: number
}) => {
  const filters: TSearchFilter[] = [
    {
      type: 'boolean',
      attribute: 'excludeMachinesWithoutMedia',
    },
  ]

  if (machine.engineHours && machine.engineHours >= 10) {
    filters.push({
      attribute: KnownMachineAttribute.ENGINE_HOURS,
      min: Math.max(0, machine.engineHours - 500),
      max: machine.engineHours + 500,
      type: 'number',
    })
  }

  if (machine.price !== null) {
    filters.push({
      attribute: KnownMachineAttribute.PRICE_NET_EUR,
      min: machine.price - 10000,
      max: machine.price + 10000,
      type: 'number',
    })
  }
  if (machine.yearOfProduction) {
    filters.push({
      attribute: KnownMachineAttribute.YEAR_OF_PRODUCTION,
      min: Math.max(1900, machine.yearOfProduction - 2),
      max: machine.yearOfProduction + 2,
      type: 'number',
    })
  }
  if (machine.enginePower) {
    filters.push({
      attribute: KnownMachineAttribute.ENGINE_HP,
      min: machine.enginePower - 20,
      max: machine.enginePower + 20,
      type: 'number',
    })
  }

  return searchMachine({
    machineType: machine.machineType,
    brands: includeBrand ? [machine.brand] : [],
    sort: {
      by: 'relevance',
      order: SortOrder.Descending,
    },
    offset: 0,
    limit: amount,
    filters,
    ip: ip ?? undefined,
  }).then((response) => response.results)
}

export const searchMachineTop = async (
  params: { sort: TSearchSortOption },
  controller?: AbortController
): Promise<TSearchResult> =>
  unauthenticatedBackendClient
    .post<{ results: OldMachineDTO[]; count: number }>(
      `/machines/top/?${MachineType.TRACTOR}=30&${MachineType.COMBINE_HARVESTER}=5&${MachineType.BALER}=5&${MachineType.FORAGE_HARVESTER_SELF_PROPELLED}=5&${MachineType.TELEHANDLER}=5`,
      params,
      {
        signal: controller?.signal,
      }
    )
    .then(getAxiosData)
    .then((res) => ({
      results: res.results.map(oldMachineDTOtoMachine),
      count: res.count,
    }))

export const getSearchResultsCount = async (
  params: Omit<TSearchPayload, 'offset' | 'limit' | 'sort'>,
  controller?: AbortController
): Promise<number> => {
  const filters: TSearchFilter[] = [
    {
      type: 'boolean',
      attribute: 'excludeMachinesWithoutMedia',
    },
  ]
  return searchMachine(
    {
      ...params,
      limit: 0,
      offset: 0,
      filters,
      sort: {
        by: 'relevance',
        order: SortOrder.Ascending,
      },
    },
    controller
  ).then((data) => data.count)
}

export const sendLeasingRequest = (data: IRequestLeasingForm) =>
  unauthenticatedBackendClient.post<any>(`/forms`, { ...data })

export const sendContactUsRequest = (data: IContactForm) =>
  unauthenticatedBackendClient.post<any>(`/forms`, { ...data })

export const sendCallBackRequest = (data: ICallbackRequestForm) =>
  unauthenticatedBackendClient.post<any>(`/forms/callback`, { ...data })
