import { createTheme } from '@mui/material/styles'
import React from 'react'
import Breakpoints from './breakpoints.module.scss'
import Colors from './colors.module.scss'
import Constants from './constants.module.scss'
import FontSizes from './fontSizes.module.scss'

export enum Font {
  Greek = 'greek',
  Default = 'default',
}

const headlineFonts: Record<Font, string> = {
  default: 'Ginto Nord, sans-serif',
  greek: 'Radiate Sans, sans-serif',
}

const systemUIFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  'system-ui',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const typographyFonts: Record<Font, string> = {
  default: systemUIFonts,
  greek: systemUIFonts,
}

export const useCreateTheme = (font: Font) => {
  const theme = React.useMemo(() => {
    const headlineFontFamily = headlineFonts[font]
    const typographyFontFamily = typographyFonts[font]
    const palette = {
      common: { black: '#000', white: '#fff' },
      background: {
        paper: Colors.backgroundPaper,
        default: Colors.backgroundDefault,
      },
      primary: {
        light: Colors.secondary,
        main: Colors.primary,
        dark: Colors.secondaryDark,
        contrastText: Colors.primary,
      },
      info: {
        main: Colors.secondary,
        dark: Colors.secondaryDark,
      },
      secondary: {
        light: Colors.secondaryLight,
        main: Colors.secondary,
        dark: Colors.secondaryDark,
        contrastText: Colors.primary,
      },
      text: {
        primary: Colors.textPrimary,
        primaryLight: Colors.primaryLight,
        secondary: Colors.textSecondary,
        disabled: Colors.textDisabled,
        invertedSecondary: Colors.textInvertedSecondary,
        invertedPrimary: Colors.textInvertedPrimary,
      },
      action: {
        active: Colors.textSecondary,
        hover: Colors.secondary,
        selected: Colors.actionSelected,
        disabled: Colors.actionDisabled,
        disabledBackground: Colors.actionDisabledBackground,
        focus: Colors.actionFocus,
      },
    }

    const breakpoints = {
      values: {
        xs: parseInt(Breakpoints.xs),
        sm: parseInt(Breakpoints.sm),
        md: parseInt(Breakpoints.md),
        lg: parseInt(Breakpoints.lg),
        xl: parseInt(Breakpoints.xl),
      },
    }

    const typography = {
      fontFamily: typographyFontFamily,
      fontSize: 16,
      h1: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h1FontSize,
        lineHeight: FontSizes.h1LineHeight,
        fontWeight: parseInt(FontSizes.h1FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h1FontSizeMobile,
          lineHeight: FontSizes.h1LineHeightMobile,
        },
      },
      h2: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h2FontSize,
        lineHeight: FontSizes.h2LineHeight,
        fontWeight: parseInt(FontSizes.h2FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h2FontSizeMobile,
          lineHeight: FontSizes.h2LineHeightMobile,
        },
      },
      h3: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h3FontSize,
        lineHeight: FontSizes.h3LineHeight,
        fontWeight: parseInt(FontSizes.h3FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h3FontSizeMobile,
          lineHeight: FontSizes.h3LineHeightMobile,
        },
      },
      h4: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h4FontSize,
        lineHeight: FontSizes.h4LineHeight,
        fontWeight: parseInt(FontSizes.h4FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h4FontSizeMobile,
          lineHeight: FontSizes.h4LineHeightMobile,
        },
      },
      h5: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h5FontSize,
        lineHeight: FontSizes.h5LineHeight,
        fontWeight: parseInt(FontSizes.h5FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h5FontSizeMobile,
          lineHeight: FontSizes.h5LineHeightMobile,
        },
      },
      h6: {
        fontFamily: headlineFontFamily,
        fontSize: FontSizes.h6FontSize,
        lineHeight: FontSizes.h6LineHeight,
        fontWeight: parseInt(FontSizes.h6FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.h6FontSizeMobile,
          lineHeight: FontSizes.h6LineHeightMobile,
        },
      },
      subtitle1: {
        fontSize: FontSizes.subtitle1FontSize,
        lineHeight: FontSizes.subtitle1LineHeight,
        fontWeight: parseInt(FontSizes.subtitle1FontWeight),
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.subtitle1FontSizeMobile,
          lineHeight: FontSizes.subtitle1LineHeightMobile,
        },
      },
      subtitle2: {
        fontSize: FontSizes.subtitle2FontSize,
        lineHeight: FontSizes.subtitle2LineHeight,
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.subtitle2FontSizeMobile,
          lineHeight: FontSizes.subtitle2LineHeightMobile,
        },
      },
      body1: {
        fontSize: FontSizes.body1FontSize,
        lineHeight: FontSizes.body1LineHeight,
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.body1FontSize,
          lineHeight: FontSizes.body1LineHeight,
        },
      },
      body2: {
        fontSize: FontSizes.body2FontSize,
        lineHeight: FontSizes.body2LineHeight,
        mobile: FontSizes.body2FontSizeMobile,
        [`@media screen and (max-width: ${Breakpoints.lg})`]: {
          fontSize: FontSizes.body2FontSize,
          lineHeight: FontSizes.body2LineHeight,
        },
      },
      button: {
        fontSize: FontSizes.buttonFontSize,
        lineHeight: FontSizes.buttonLineHeight,
      },
    }

    return createTheme({
      components: {
        MuiChip: {
          variants: [
            {
              props: {
                variant: 'filled',
                size: 'small',
              },
              style: {
                color: Colors.textPrimary,
                backgroundColor: Colors.greyLight,
                height: 'auto',
                padding: `${parseInt(Constants.base) / 2}px 0`,
              },
            },
          ],
        },
      },
      palette,
      breakpoints,
      shape: {
        borderRadius: 0,
      },
      typography,
    })
  }, [font])

  return { theme }
}

export enum OverlayColor {
  Bright = 'bright',
  Dark = 'dark',
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    action: true
  }
}
