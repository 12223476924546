import { Search } from '@mui/icons-material'
import { MobileOverlay } from '@renderer-ui-library/atoms'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { SearchCoverBlockFields } from 'blocks/SearchCoverBlock/SearchCoverBlockFields/SearchCoverBlockFields'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { tracker } from 'utils/tracking/tracker'
import { DynamicSearchDesktopFields } from './dynamicSearchDesktopFields'
import styles from './searchOverlay.module.scss'

export interface TSearchParams {
  brand: string | null
  machineType: MachineType
  model: string | null
  resultsCount: number | null
}

export interface SearchOverlayProps {
  searchParams: TSearchParams
}

export const SearchOverlay: React.FC<SearchOverlayProps> = React.memo(
  ({ searchParams }) => {
    const { isMobile } = useMediaQuery()
    const { t } = useTranslation(localeNamespace.common)
    const [searchOpen, setSearchOpen] = useState(false)

    const handleOpenClick = useCallback(() => {
      tracker.trackEvent({
        name: 'click',
        target_name: 'top_menu.search_icon',
        is_interaction: true,
      })
      setSearchOpen(true)
    }, [])
    const close = useCallback(() => setSearchOpen(false), [])

    return (
      <div className={styles.searchOverlayWrapper}>
        <div className={styles.searchIconWrapper} onClick={handleOpenClick}>
          <Search fontSize='small' color='inherit' />
        </div>
        {isMobile ? (
          createPortal(
            <div
              className={classNames(styles.searchMobileWrapper, {
                [styles.searchWrapperOpen]: searchOpen,
              })}
            >
              <MobileOverlay
                open={searchOpen}
                title={t(translations.searchCoverBlockButtonTextFindOffers)}
                onCloseClick={close}
              >
                <SearchCoverBlockFields
                  brand={searchParams.brand}
                  model={searchParams.model}
                  machineType={searchParams?.machineType}
                  onClose={close}
                  colorOverlay={OverlayColor.Bright}
                  placement='header'
                />
              </MobileOverlay>
            </div>,
            document.body
          )
        ) : (
          <DynamicSearchDesktopFields
            open={searchOpen}
            brand={searchParams.brand}
            model={searchParams.model}
            machineType={searchParams.machineType}
            resultsCount={searchParams?.resultsCount}
            onClose={close}
          />
        )}
      </div>
    )
  }
)

SearchOverlay.displayName = 'SearchOverlay'
