import {
  localeNamespace,
  TLocale,
} from '@website-shared-library/machine/i18n/Locale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { defaultLocale } from 'utils/constants'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import { getSearchResultsUrl } from '../routing/getSearchResultsUrl'
import { AvailableMachineTypes } from './AvailableMachineTypes'
import { useMachineTypeName } from './useMachineTypeName'

type TMachineCategoryItem = {
  name: string
  machineType: MachineType
  url: string
}

export type TMachineCategory = {
  name: string
  machines: TMachineCategoryItem[]
}

export const useMachineCategoryMap = () => {
  const { t } = useTranslation(localeNamespace.common)
  const { getMachineTypeName } = useMachineTypeName()
  const { getPrioritizedUrl } = usePrioritizedUrl()
  const { locale: routerLocale } = useRouter()
  const locale = (routerLocale ?? defaultLocale) as TLocale

  const machineCategoryItemWithCategoryPage = useCallback(
    (machineType: MachineType) => ({
      name: getMachineTypeName(machineType, 'plural'),
      machineType,
      url: getPrioritizedUrl({
        machineCategoryPage: {
          data: {
            id: 0,
            attributes: {
              id: '1',
              locale,
              machineType,
            },
          },
        },
      }) as string,
    }),
    [getMachineTypeName, getPrioritizedUrl, locale]
  )

  const getItem = useCallback(
    (machineType: MachineType) => {
      const machineTypeName = getMachineTypeName(machineType, 'plural')
      return {
        name: machineTypeName,
        machineType,
        url: getSearchResultsUrl(locale, { machineTypeName }),
      }
    },
    [getMachineTypeName, locale]
  )

  const machineCategoryMap = useMemo(() => {
    const {
      TRACTOR,
      BALER,
      BALE_WRAPPER,
      COMBINE_HARVESTER,
      FORAGE_HARVESTER_SELF_PROPELLED,
      BEET_HARVESTING_EQUIPMENT,
      MOWER,
      POTATO_HARVESTER,
      FERTILISER_SPREADER,
      SPRAYER,
      PRESSES_AND_ROLLER,
      TOPPER,
      ROTARY_RAKE_WINDROWER,
      FORAGING_EQUIPMENT_OTHER,
      CULTIVATOR,
      PLOUGH,
      SEEDBED_COMBINATION,
      DISC_HARROW_AND_COMPACT_DISC,
      POWER_HARROW,
      ...otherMachines
    } = AvailableMachineTypes

    const sortAlphabetically = (
      a: TMachineCategoryItem,
      b: TMachineCategoryItem
    ) => {
      if (a.name === b.name) {
        return 0
      }

      return a.name > b.name ? 1 : -1
    }

    const categorizedMachinesMap: TMachineCategory[] = [
      {
        name: t(translations.headerMainNavCategoryTractors),
        machines: [
          {
            ...machineCategoryItemWithCategoryPage(TRACTOR),
            name: t(translations.headerMainNavCategoryItemAllTractors),
          },
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryHarvesting),
        machines: [
          machineCategoryItemWithCategoryPage(BALER),
          getItem(BALE_WRAPPER),
          machineCategoryItemWithCategoryPage(COMBINE_HARVESTER),
          getItem(FORAGE_HARVESTER_SELF_PROPELLED),
          getItem(BEET_HARVESTING_EQUIPMENT),
          getItem(MOWER),
          machineCategoryItemWithCategoryPage(POTATO_HARVESTER),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategorySpreadersSprayers),
        machines: [getItem(FERTILISER_SPREADER), getItem(SPRAYER)].sort(
          (a, b) => sortAlphabetically(a, b)
        ),
      },
      {
        name: t(translations.headerMainNavCategoryForaging),
        machines: [
          getItem(PRESSES_AND_ROLLER),
          getItem(TOPPER),
          getItem(ROTARY_RAKE_WINDROWER),
          getItem(FORAGING_EQUIPMENT_OTHER),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryTillage),
        machines: [
          getItem(CULTIVATOR),
          getItem(PLOUGH),
          getItem(SEEDBED_COMBINATION),
          getItem(DISC_HARROW_AND_COMPACT_DISC),
          getItem(POWER_HARROW),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryOther),
        machines: [
          ...Object.values(otherMachines).map((machineType) => {
            if (machineType === MachineType.TELEHANDLER) {
              return machineCategoryItemWithCategoryPage(machineType)
            }
            return getItem(machineType)
          }),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
    ]

    return categorizedMachinesMap
  }, [t, machineCategoryItemWithCategoryPage, getItem])

  return { machineCategoryMap }
}
